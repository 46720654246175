<template>
  <div>
    <hr v-if="itemIndex !== 0" />
    <div class="row">
      <!-- Title -->
      <div class="col">
        <WeInput
          v-bind:prepend="itemIndex + 1"
          v-model="item.title"
          v-bind:placeholder="$t('title')"
        >
          <template #append>
            <button class="btn btn-outline-danger" v-on:click="onRemove">
              <i class="fas fa-trash"></i>
            </button>
          </template>
        </WeInput>
      </div>
      <!-- ./Title -->
    </div>

    <!-- Content -->
    <WeEditor
      v-bind:access-token="session.accessToken"
      v-bind:name="name"
      v-model="item.content"
    />
    <!-- ./Content -->
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ListItem",
  props: {
    data: {
      default: () => [],
    },
    itemIndex: {
      default: 0,
    },
    name: {
      default: "product",
    },
  },
  methods: {
    onRemove() {
      if (this.item.content && this.item.content.length) {
        this.$swal({
          title: this.$t("doYouApproveTheAction"),
          text: this.$t("descriptionAreaWillBeRemoved"),
          icon: "question",
          showCancelButton: true,
          cancelButtonText: this.$t("cancel"),
        }).then((confirm) => {
          if (confirm.isConfirmed) {
            this.clearItem();
          }
        });
      } else {
        this.clearItem();
      }
      this.$emit("input", this.computedData);
    },
    clearItem() {
      this.data.splice(this.itemIndex, 1);
    },
  },
  computed: {
    ...mapState(["session"]),
    item() {
      return this.data[this.itemIndex];
    },
  },
};
</script>